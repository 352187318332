import { FriendEmailInvitation } from "types/friend.d";
import {
  INVITATION_ACCEPT_ENDPOINT,
  GET_FRIEND_QR_CODE_INVITATION_TOKEN,
  INVITATIONS_ENDPOINT,
  CHECK_INVITATION_ENDPOINT,
  INVITE_OWNER_ENDPOINT,
  RETRIEVE_OWNER_INVITATION_ENDPOINT,
  HOUSE_APPLICATION_ENDPOINT,
} from "urls/api";
import fetchApi from "./fetchApi";
import {
  InvitableStatusValues,
  CheckInvitationConnectionError,
  UninvitableStatusValues,
} from "components/Modals/InviteFriendViaEmailModal/InviteFriendViaEmailModal.enums";
import { InviteOwnerForm } from "components/Modals/InviteOwnerModal/InviteOwnerModal.d";
import { ApplicationFormData } from "views/HouseApplication/types";

export const acceptInvitation = (id: string) => {
  const response = fetchApi(INVITATION_ACCEPT_ENDPOINT(id), {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
  return response;
};

interface InvitationResponse extends Response {
  token: string;
}

interface EmailCheckResponse {
  message: string;
  status:
    | InvitableStatusValues
    | CheckInvitationConnectionError
    | UninvitableStatusValues;
}

interface OwnerInvitationResponse {
  email: string;
  phone_number: string;
}

export const fetchFriendInvitationToken = () =>
  fetchApi<InvitationResponse>(GET_FRIEND_QR_CODE_INVITATION_TOKEN);

export const fetchSendEmailInvitation = (data: FriendEmailInvitation) =>
  fetchApi(INVITATIONS_ENDPOINT, {
    body: JSON.stringify(data),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });

export const fetchCheckEmailForInvitation = (email: string) =>
  fetchApi<EmailCheckResponse>(CHECK_INVITATION_ENDPOINT, {
    body: JSON.stringify({ email }),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });

export const inviteOwner = (data: InviteOwnerForm) => {
  const inviteOwner = fetchApi(INVITE_OWNER_ENDPOINT, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return inviteOwner;
};

export const retrieveOwnerInvitation = (token: string) => {
  return fetchApi<OwnerInvitationResponse>(
    RETRIEVE_OWNER_INVITATION_ENDPOINT(token)
  );
};

export const sendHouseApplication = async (data: ApplicationFormData) => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    if (key === "images") {
      Array.from(data.images).map((image, index) => {
        formData.append(`images[${index}]file`, image);
      });
    } else if (key === "house_type" || key === "house_size") {
      formData.append(key, value || "");
    } else {
      formData.append(key, value);
    }
  }

  const response = await fetchApi(HOUSE_APPLICATION_ENDPOINT, {
    method: "POST",
    body: formData,
  });
  return response;
};
