import { FC, useState, useEffect } from "react";
import { HousesList } from "components/HousesList";
import { fetchHouses } from "services/Houses";
import { HouseItem } from "components/HousesList/HousesList.d";
import Layout from "components/Layout";
import { fetchFriendInvitationToken } from "services/Invitation";
import { useProfile } from "context/Profile";
import {
  ContactUsExternalLink,
  SectionBox,
  StyledContainer,
  StyledContentTitle,
  StyledContentTypography,
  StyledHousesListContainer,
  StyledPaginationContainer,
  ButtonContainer,
  ButtonWrapper,
} from "./Houses.styled";
import { useSearchParams } from "react-router-dom";
import { Loader } from "components/Loader/Loader";
import { Pagination } from "components/Pagination";
import { Box, Typography } from "components";
import HousesFilter from "components/HousesFilter/HousesFilter";
import { Option } from "components/Select/Select.d";
import { useCalculator } from "context/Calculator";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { Modal } from "components/Modal";
import { COLORS } from "theme";
import { hexToRGB } from "global/utils";
import IdeaImage from "assets/idea_image.jpg";
import MembersImage from "assets/members_image.jpg";
import HowItWorksImage from "assets/howitworks_image.jpg";
import ApplyImage from "assets/apply_image.jpg";
import { Image } from "components/Image";
import { CONTACT_PAGE } from "urls/frontend";
import { useModal } from "global/hooks/useModal";
import { Button } from "components/Button";
import { ButtonSize } from "components/Button/Button.enums";
import QRCodeBoxes from "components/QRCodeBoxes/QRCodeBoxes";
import { RequestFullAccessHeader } from "components/ApplyForMembership/RequestFullAccessHeader";
import { SecondaryFooter } from "components/SecondaryFooter";

const HOUSES_PER_PAGE = 15;

const Houses: FC = () => {
  const [houses, setHouses] = useState<Array<HouseItem>>([]);
  const [totalHouses, setTotalHouses] = useState<number>(0);
  const [invitationToken, setInvitationToken] = useState<string>("");
  const [filters, setFilters] = useState<Array<any>>([]);
  const [options, setOptions] = useState<Array<Option>>([]);
  const [selectedOption, setSelectedOption] = useState<Option>();
  const [searchParams] = useSearchParams();
  const profile = useProfile();
  const profileInfo = profile?.profileInfo;
  const appliedForMembership =
    profile?.profileInfo?.applied_for_membership || false;
  const [isLoaded, setisLoaded] = useState(false);
  const pagesNumber = Math.ceil(totalHouses / HOUSES_PER_PAGE);
  const { adults, children, infants, startDate, endDate } = useCalculator();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const { isShown, toggle } = useModal(true);

  const getToken = async () => {
    const response = await fetchFriendInvitationToken();
    if (response.ok) {
      setInvitationToken(response.parsedBody.token);
    }
  };

  const demo_token = searchParams.get("demo_token");
  const page = searchParams.get("page");

  const userType = localStorage.getItem("user_type") || "";
  const isBasicUserType = !!userType && JSON.parse(userType) === "BASIC";

  useEffect(() => {
    if (demo_token) {
      const getHousesExamples = async () => {
        const queryParams = new URLSearchParams({
          demo_token: demo_token,
        });
        const response = await fetchHouses(queryParams);
        setHouses(response.parsedBody.results.houses);
        setFilters(response.parsedBody.results.filters);
        setisLoaded(true);
      };
      getHousesExamples();
    } else {
      const getHouses = async () => {
        const queryParams = new URLSearchParams({
          location: selectedOption ? selectedOption.value : "",
          date_from: startDate?.toISOString().slice(0, 10) ?? "",
          date_to: endDate?.toISOString().slice(0, 10) ?? "",
          adults: `${adults}`,
          children: `${children}`,
          infants: `${infants}`,
          page: `${page ?? 1}`,
        });
        const response = await fetchHouses(queryParams);
        setHouses(response.parsedBody.results.houses);
        setFilters(response.parsedBody.results.filters);
        setTotalHouses(response.parsedBody.results.total_houses);
        setisLoaded(true);
      };

      getHouses();
    }
  }, [searchParams, page, profile?.selectedLanguage]);

  useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    if (profileInfo?.access_type === "ALL") {
      getToken();
    }
  }, [profileInfo]);

  useEffect(() => {
    if (filters.length !== 0) {
      const formattedOptions = filters.reduce(
        (prevVal: Array<Option>, currentValue) => {
          const option: Option = {
            name: `${currentValue.name} (${currentValue.counter})`,
            value: currentValue.country_code,
          };
          prevVal.push(option);
          return prevVal;
        },
        []
      );
      setOptions(formattedOptions);
      setSelectedOption(formattedOptions[0]);
    }
  }, [filters]);

  const onClose = () => {
    toggle();
    profile?.setIsFirstLogin(false);
  };

  return (
    <>
      {isBasicUserType && (
        <RequestFullAccessHeader isRequestSent={appliedForMembership} />
      )}
      <Layout>
        {isLoaded ? (
          <>
            {profile?.isFirstLogin && isShown && (
              <Modal
                withCloseButton
                onConfirm={onClose}
                isShown={isShown}
                onClose={onClose}
              >
                <StyledContainer>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <StyledContentTitle
                      variant="h2"
                      color={COLORS.typography.headline}
                    >
                      {translation["welcomeHouseOfFriends"][selectedLanguage]}
                    </StyledContentTitle>
                  </Box>
                  <SectionBox
                    display="flex"
                    flexWrap="wrap"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Image variant={"howitworks"} src={IdeaImage} />
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <StyledContentTitle
                        variant="h2"
                        color={COLORS.typography.headline}
                      >
                        {translation["theIdea"][selectedLanguage]}
                      </StyledContentTitle>
                      <StyledContentTypography
                        variant="caption"
                        color={hexToRGB(COLORS.typography.headline, "0.7")}
                      >
                        {
                          translation["houseOfFriendsGivingAccessToRental"][
                            selectedLanguage
                          ]
                        }
                      </StyledContentTypography>
                    </Box>
                  </SectionBox>
                  <SectionBox
                    display="flex"
                    flexWrap="wrap"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Image variant={"howitworks"} src={MembersImage} />
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <StyledContentTitle
                        variant="h2"
                        color={COLORS.typography.headline}
                      >
                        {translation["theMembers"][selectedLanguage]}
                      </StyledContentTitle>
                      <StyledContentTypography
                        variant="caption"
                        color={hexToRGB(COLORS.typography.headline, "0.7")}
                      >
                        {
                          translation[
                            "internationalCommunityOfOwnersAndFriends"
                          ][selectedLanguage]
                        }
                      </StyledContentTypography>
                    </Box>
                  </SectionBox>
                  <SectionBox
                    display="flex"
                    flexWrap="wrap"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Image variant={"howitworks"} src={HowItWorksImage} />
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <StyledContentTitle
                        variant="h2"
                        color={COLORS.typography.headline}
                      >
                        {translation["howItWorks"][selectedLanguage]}
                      </StyledContentTitle>
                      <StyledContentTypography
                        variant="caption"
                        color={hexToRGB(COLORS.typography.headline, "0.7")}
                      >
                        {translation["rentalProcess"][selectedLanguage]}
                      </StyledContentTypography>
                    </Box>
                  </SectionBox>
                  <SectionBox
                    display="flex"
                    flexWrap="wrap"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={7}
                    mb={4}
                  >
                    <Image variant={"howitworks"} src={ApplyImage} />
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <StyledContentTitle
                        variant="h2"
                        color={COLORS.typography.headline}
                      >
                        {translation["wantToApplyWithAHouse"][selectedLanguage]}
                      </StyledContentTitle>
                      <StyledContentTypography
                        variant="caption"
                        color={hexToRGB(COLORS.typography.headline, "0.7")}
                      >
                        {
                          translation["doYouHaveAnIconicVacationHome"][
                            selectedLanguage
                          ]
                        }
                        <ContactUsExternalLink to={CONTACT_PAGE}>
                          {translation["tellUsMore"][selectedLanguage]}
                        </ContactUsExternalLink>{" "}
                        {
                          translation["aboutYourHomeForEvaluation"][
                            selectedLanguage
                          ]
                        }
                      </StyledContentTypography>
                    </Box>
                  </SectionBox>
                  <ButtonWrapper
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ButtonContainer>
                      <Button size={ButtonSize.SMALL} onClick={toggle}>
                        {translation["searchHouse"][selectedLanguage]}
                      </Button>
                    </ButtonContainer>
                  </ButtonWrapper>
                </StyledContainer>
              </Modal>
            )}
            <HousesFilter
              setisLoaded={setisLoaded}
              options={options}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              setHouses={setHouses}
              setTotalHouses={setTotalHouses}
            />
            <StyledHousesListContainer m={2.25}>
              <HousesList
                houses={houses}
                demo_token={demo_token}
                appliedForMembership={appliedForMembership}
              />
              <>
                {!isBasicUserType && (
                  <StyledPaginationContainer
                    pt={8}
                    pb={5}
                    display="grid"
                    gridAutoFlow="row"
                    justifyItems="center"
                    alignItems="center"
                    gridGap={3}
                  >
                    {pagesNumber > 0 && <Pagination count={pagesNumber} />}
                    <Typography variant="caption">{`${totalHouses} ${translation["offers"][selectedLanguage]} ${translation["found"][selectedLanguage]}`}</Typography>
                  </StyledPaginationContainer>
                )}
              </>
            </StyledHousesListContainer>
            <SecondaryFooter
              title={translation["inviteWithQRCode"][selectedLanguage]}
              subTitle={translation["letYourFriendsScan"][selectedLanguage]}
              token={invitationToken}
            />
            {/* {profileInfo?.can_add_friends && (
              <QRCodeBoxes token={invitationToken} />
            )} */}
          </>
        ) : (
          <Loader />
        )}
      </Layout>
    </>
  );
};

export default Houses;
