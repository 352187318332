import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { FC } from "react";
import { COLORS } from "theme";
import { SecondaryFooterProps } from "./SecondaryFooter.d";
import {
  StyledHeadline,
  StyledSubtitle,
  StyledContainer,
  StyledButton,
  StyledCanvas,
  StyledQRCodeContainer,
  StyledArrowIcon,
} from "./SecondaryFooter.styled";
import QRCode from "react-qr-code";
import { useWindowSize } from "global/hooks/useWindowSize";
import { FRONTEND_HOST } from "urls/frontend";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const SecondaryFooter: FC<SecondaryFooterProps> = ({
  title,
  subTitle,
  token,
}) => {
  const { isTablet, isDesktop } = useWindowSize();
  const invitationLink = token && `${FRONTEND_HOST}/invite-friend/${token}`;
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const getQRCodeSize = () => {
    if (isTablet) return 206;
    if (isDesktop) return 214;
    return 265;
  };

  const triggerDownload = (imgURI: string) => {
    const evt = new MouseEvent("click", {
      view: window,
      bubbles: false,
      cancelable: true,
    });

    const a = document.createElement("a");
    a.setAttribute("download", "house-of-friends-qr-code.png");
    a.setAttribute("href", imgURI);
    a.setAttribute("target", "_blank");

    a.dispatchEvent(evt);
  };

  const saveQRCode = () => {
    const svg = document.getElementById("qr-code");
    const canvas = document.getElementById("canvas") as HTMLCanvasElement;

    if (svg && canvas) {
      const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
      const data = new XMLSerializer().serializeToString(svg);
      const DOMURL = window.URL || window.webkitURL || window;

      const img = new Image();
      const svgBlob = new Blob([data], { type: "image/svg+xml" });
      const url = DOMURL.createObjectURL(svgBlob);

      img.onload = function () {
        ctx.drawImage(img, 0, 0);
        DOMURL.revokeObjectURL(url);

        const imgURI = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");

        triggerDownload(imgURI);
      };

      img.src = url;
    }
  };

  return (
    <StyledContainer
      py={9.5}
      px={2.3}
      display="grid"
      gridGap={6.5}
      alignItems="center"
    >
      <div>
        <StyledHeadline variant="h1" color={COLORS.typography.secondary.main}>
          {title}
        </StyledHeadline>
        {subTitle && (
          <StyledSubtitle
            variant="body"
            color={COLORS.typography.secondary.main}
          >
            {subTitle}
          </StyledSubtitle>
        )}
        <StyledButton
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.SMALL}
          onClick={saveQRCode}
          icon={
            <StyledArrowIcon
              stroke={COLORS.accent.primary}
              fill="none"
              width="20px"
              height="20px"
            />
          }
        >
          {translation["saveToPictures"][selectedLanguage]}
        </StyledButton>
      </div>
      {invitationLink && (
        <StyledQRCodeContainer p={5}>
          <QRCode value={invitationLink} size={getQRCodeSize()} id="qr-code" />
        </StyledQRCodeContainer>
      )}
      <StyledCanvas
        id="canvas"
        height={getQRCodeSize()}
        width={getQRCodeSize()}
      />
    </StyledContainer>
  );
};
