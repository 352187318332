import { Box } from "components/Box";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { SIZES, COLORS } from "theme";
import MobileQrCodeBackgroundImage from "assets/qr-code-images/qr_code_image_mobile.png";
import TabletQrCodeBackgroundImage from "assets/qr-code-images/qr_code_image_tablet.png";
import DesktopQrCodeBackgroundImage from "assets/qr-code-images/qr_code_image_desktop.png";

import AddHouseOwnerMobile from "assets/man-at-beach/man_beach_mobile.png";
import AddHouseOwnerTablet from "assets/man-at-beach/man_beach_tablet.png";
import AddHouseOwnerDesktop from "assets/man-at-beach/man_beach_desktop.png";

import AddHouseMobile from "assets/add_house/add_house_mobile.png";
import AddHouseTablet from "assets/add_house/add_house_tablet.png";
import AddHouseDesktop from "assets/add_house/add_house_desktop.png";

import { Typography } from "components/Typography";
import { Button } from "components/Button";

import { ReactComponent as GoogleWalletIcon } from "assets/icons/icon_google_wallet.svg";
import { ReactComponent as AppleWalletIcon } from "assets/icons/icon_apple_wallet.svg";

interface QRCodeDescription {
  isOrange?: boolean;
}

interface Variant {
  boxVariant?: "default" | "single-box";
}

export const StyledContainer = styled(Box)`
  display: grid;
  margin-bottom: ${pxToRem(80)};
  row-gap: ${pxToRem(24)};

  ${SIZES.media.tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: ${pxToRem(50)};
    grid-column-gap: ${pxToRem(20)};
    padding: 0 ${pxToRem(32)};
    margin-bottom: ${pxToRem(100)};
  }

  ${SIZES.media.desktop} {
    row-gap: ${pxToRem(60)};
  }
`;

export const QRCodeContainer = styled(Box)<Variant>`
  min-height: ${pxToRem(376)};
  background-image: url(${MobileQrCodeBackgroundImage});
  background-size: 100% 100%;

  ${SIZES.media.tablet} {
    min-height: initial;
    grid-column: 1/3;
    background-image: url(${TabletQrCodeBackgroundImage});
  }

  ${SIZES.media.desktop} {
    background-image: url(${DesktopQrCodeBackgroundImage});
  }
`;

export const QRCodeContent = styled(Box)`
  display: grid;
  grid-auto-flow: row;
  margin: ${pxToRem(32)} ${pxToRem(18)} 0;
  color: ${COLORS.background.main};

  ${SIZES.media.tablet} {
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    margin: ${pxToRem(40)};
  }
`;

export const QRCodeDescription = styled(Typography)<QRCodeDescription>`
  text-align: center;
  text-edge: cap;
  font-family: "Playfair Display";
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.63px;
  ${({ isOrange }) =>
    isOrange &&
    `
    color: ${COLORS.accent.primary};
  `}

  ${SIZES.media.tablet} {
    font-family: "Playfair Display";
    text-align: left;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0.9px;
  }
`;

export const ButtonsContainer = styled(Box)`
  display: grid;
  justify-content: center;
  margin: ${pxToRem(32)} 0 ${pxToRem(24)};
  grid-auto-flow: column;

  ${SIZES.media.tablet} {
    justify-content: flex-start;
    grid-gap: ${pxToRem(16)};
    grid-column: 1;
  }
`;

export const QRCodeButton = styled(Button)`
  min-width: 130px;
  padding: ${pxToRem(18)} ${pxToRem(8)};
  border-radius: 8px;
  background-color: ${COLORS.typography.secondary.dark} !important;
  color: ${COLORS.typography.secondary.main};
  border: 1px solid;
`;

export const StyledQRCodeContainer = styled(Box)`
  background-color: ${COLORS.typography.secondary.main};
  padding: ${pxToRem(4)};
  margin: 0 auto;
  align-self: center;

  ${SIZES.media.tablet} {
    grid-row: 1/3;
    grid-column: 2;
    padding: ${pxToRem(20)};
  }
`;

export const AddHouseOwnerContainer = styled(Box)`
  margin: ${pxToRem(32)} ${pxToRem(18)} 0;

  ${SIZES.media.tablet} {
    margin: 0;
  }
`;

export const AddHouseOwnerImage = styled(Box)<Variant>`
  min-height: ${pxToRem(160)};
  background-image: url(${AddHouseOwnerMobile});
  background-size: 100% 100%;

  ${({ boxVariant }) =>
    boxVariant === "single-box" &&
    `
    height: 100%;
`}

  ${SIZES.media.tablet} {
    min-height: ${pxToRem(216)};
    background-image: url(${AddHouseOwnerTablet});
  }

  ${SIZES.media.desktop} {
    background-image: url(${AddHouseOwnerDesktop});
  }
`;

export const AddContent = styled(Box)`
  background-color: ${COLORS.accent.boxes};
  padding: ${pxToRem(24)} 0;

  ${SIZES.media.tablet} {
    padding: ${pxToRem(32)} 0;
  }

  ${SIZES.media.desktop} {
    padding: ${pxToRem(32)} 0 ${pxToRem(50)};
    row-gap: ${pxToRem(60)};
  }
`;

export const AddHeader = styled(Typography)`
  color: ${COLORS.typography.headline};
  text-align: center;
  font-family: "Playfair Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.72px;
  margin-bottom: ${pxToRem(16)};

  ${SIZES.media.tablet} {
    font-size: 26px;
    letter-spacing: 1.04px;
  }
`;

export const AddDescription = styled(Typography)`
  color: ${COLORS.typography.body};
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0 42px 32px;
  opacity: 0.8;

  ${SIZES.media.tablet} {
    font-size: 16px;
    line-height: 30px; /* 187.5% */
  }
`;

export const AddHouseContainer = styled(Box)<Variant>`
  margin: ${pxToRem(24)} ${pxToRem(18)} 0;

  ${({ boxVariant }) =>
    boxVariant === "single-box" &&
    `
    margin-top: ${pxToRem(-24)};
  `}

  ${SIZES.media.tablet} {
    margin: 0;
  }
`;

export const StyledAddHouseImage = styled(Box)<Variant>`
  min-height: ${pxToRem(160)};
  background-image: url(${AddHouseMobile});
  background-size: 100% 100%;

  ${({ boxVariant }) =>
    boxVariant === "single-box" &&
    `
  height: 100%;
`}

  ${SIZES.media.tablet} {
    min-height: ${pxToRem(216)};
    background-image: url(${AddHouseTablet});
  }

  ${SIZES.media.desktop} {
    background-image: url(${AddHouseDesktop});
  }
`;

export const StyledButton = styled(Button)`
  text-decoration: none;
`;

export const StyledAppleWalletIcon = styled(AppleWalletIcon)`
  &:hover {
    cursor: pointer;
  }
`;

export const StyledGoogleWalletIcon = styled(GoogleWalletIcon)`
  &:hover {
    cursor: pointer;
  }
`;
