import { Box } from "components/Box";
import styled from "styled-components";
import { pxToRem } from "global/utils";
import { Typography } from "components/Typography";
import { Button } from "components/Button";
import { COLORS, SIZES } from "theme";
import FooterBackgroundImage from "assets/secondary-footer-bg.jpg";
import MobileFooterBackgroundImage from "assets/secondary-footer-bg-mobile.jpg";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";

export const StyledHeadline = styled(Typography)`
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.03em;
  margin-bottom: ${pxToRem(12)};
  font-weight: 400;

  ${SIZES.media.phone} {
    font-size: 60px;
    line-height: 70px;
  }

  ${SIZES.media.desktop} {
    width: 75%;
    margin-bottom: ${pxToRem(30)};
  }
`;

export const StyledSubtitle = styled(Typography)`
  line-height: 30px;
  margin-bottom: ${pxToRem(30)};
`;

export const StyledContainer = styled(Box)`
  background: linear-gradient(
      85.41deg,
      rgba(12, 13, 36, 0.5) 0.51%,
      rgba(13, 14, 37, 0) 73.89%
    ),
    url(${MobileFooterBackgroundImage});
  height: fit-content;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  justify-items: center;

  ${SIZES.media.phone} {
    background: linear-gradient(
        85.41deg,
        rgba(12, 13, 36, 0.5) 0.51%,
        rgba(13, 14, 37, 0) 73.89%
      ),
      url(${FooterBackgroundImage});
    background-size: cover;
    background-position: bottom;
    padding-top: ${pxToRem(55)};
    padding-left: ${pxToRem(32)};
  }

  ${SIZES.media.tablet} {
    padding: ${pxToRem(50)} ${pxToRem(60)};
  }

  ${SIZES.media.desktop} {
    padding: ${pxToRem(84)} ${pxToRem(80)};
  }
`;

export const StyledButton = styled(Button)`
  width: 230px;
`;

export const StyledCanvas = styled.canvas`
  height: 0;
  width: 0;
  visibility: hidden;
`;

export const StyledQRCodeContainer = styled(Box)`
  background-color: ${COLORS.typography.secondary.main};

  ${SIZES.media.tablet} {
    grid-column: 2;
  }

  ${SIZES.media.desktop} {
    padding: ${pxToRem(50)};
  }
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  transform: rotate(90deg);
`;
